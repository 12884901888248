import React from 'react';
import './Button.css';

interface ButtonProps {
    id?: string;
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    secondary?: boolean;
}

const Button = ({id = 'default-id', secondary, title, onClick, disabled = false}: ButtonProps) => {
  return (
    <button id={id} disabled={disabled} className={`button ${secondary ? 'secondary' : ''}`} onClick={onClick}>{title}</button>
  )
};

export default Button