import { useState, useEffect } from 'react';
import { getCookie, setCookie } from "./cookie-typescript-utils";

export function useCountry() {
  const [country, setCountry] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchCountry() {
      const localCountry = getCookie('country');

        if (localCountry) {
          setCountry(localCountry);
          setIsLoading(false);

          return;
        }

        try {
          const response = await fetch("https://ipapi.co/json");
          const data = await response.json();

          if (!data) {
            throw new Error("Unable to fetch the country");
          }

          const countryCode = data.country_code.toLowerCase();
          setCookie('country', countryCode, 1 * 60 * 60 * 1000);

          if (isMounted) {
            setCountry(countryCode || 'us');
            setIsLoading(false);
          }
        } catch (err) {
          setError(err instanceof Error ? err : new Error('Unknown error'));
          setIsLoading(false);
        }
    }

    if(isMounted) {
      fetchCountry();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return { country, isLoading, error };
}